<template>
  <div class="space-y-4">
    <div v-for="(item, i) in groupedItems" :key="i">
      <h4 v-if="item.title" class="h6 mb-3">
        {{ item.title }}
      </h4>
      <brand-list v-if="item.children" :items="item.children" :color="color" />
      <brand-list v-if="$slots.default" :color="color">
        <slot />
      </brand-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandListGroup',
  props: {
    items: {
      type: Array,
      default: () => {}
    },
    color: {
      type: String,
      default: 'blue'
    }
  },
  computed: {
    groupedItems() {
      const result = this.items.reduce(
        (arr, item) => {
          if (item.startsWith('#')) {
            arr.push({ title: item.replace(/^#/, ''), children: [] })
          } else {
            arr[arr.length - 1].children.push(item)
          }
          return arr
        },
        [{ children: [] }]
      )
      return result.filter((s) => s.children.length)
    }
  }
}
</script>
